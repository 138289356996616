/* custom-datepicker.css */

.react-datepicker {
  font-family: inherit;
  border: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.react-datepicker__header {
  background-color: white;
  border-bottom: none;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.125rem;
  width: 2rem;  /* Matches w-8 */
  height: 2rem; /* Matches h-8 */
}

.react-datepicker__day-name {
  color: #6B7280;
  font-size: 0.875rem;
}

.react-datepicker__day {
  font-size: 0.875rem;
  border-radius: 9999px;
}

.react-datepicker__day--selected, 
.react-datepicker__day--keyboard-selected {
  background-color: #3B82F6;
  color: white;
}

.react-datepicker__day--selected:hover, 
.react-datepicker__day--keyboard-selected:hover {
  background-color: #2563EB;
}

.react-datepicker__day:hover {
  background-color: #E5E7EB;
}

.react-datepicker__day--today {
  font-weight: normal;
}
