.logo-container {
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .logo-container:hover {
    transform: translateY(-2px) scale(1.02);
  }
  
  .logo-icon {
    color: #3b82f6; /* Changed to match blue-500 */
    font-size: 1.5rem;
    transform-origin: center;
    animation: float 3s ease-in-out infinite;
    transition: all 0.3s ease;
  }
  
  .logo-container:hover .logo-icon {
    animation: spin-bounce 0.6s cubic-bezier(0.28, 0.84, 0.42, 1);
    color: #2563eb; /* Changed to blue-600 */
  }
  
  .logo-text {
    font-size: 1.8rem;
    font-weight: 700;
    background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%); /* Changed to match button gradient */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    transition: all 0.3s ease;
    position: relative;
    line-height: 1.2;
    padding: 2px 0;
    margin: 0;
  }
  
  .logo-container:hover .logo-text {
    background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%); /* Darker blue gradient on hover */
    -webkit-background-clip: text;
    background-clip: text;
    letter-spacing: 0.5px;
  }
  
  /* Optional: Add subtle text shadow for depth */
  .logo-container:hover .logo-text::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
    filter: blur(20px);
    opacity: 0.15;
    z-index: -1;
    transition: opacity 0.3s ease;
  }
  
  @keyframes float {
    0%, 100% { transform: rotate(-10deg) translateY(0); }
    50% { transform: rotate(-10deg) translateY(-2px); }
  }
  
  @keyframes spin-bounce {
    0% { transform: rotate(-10deg) scale(1); }
    50% { transform: rotate(180deg) scale(1.2); }
    100% { transform: rotate(360deg) scale(1); }
  }