@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes wave {
  0%, 100% { transform: translateY(0) translateX(0); }
  25% { transform: translateY(-30px) translateX(15px); }
  75% { transform: translateY(-20px) translateX(-15px); }
}

.animate-wave {
  animation: wave 15s ease-in-out infinite;
}

.animate-wave-slow {
  animation: wave 20s ease-in-out infinite;
}

.animate-wave-slower {
  animation: wave 30s ease-in-out infinite;
}

/* Custom Scrollbar Styling */
.custom-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #cbd5e1 #f8fafc; /* Thumb color / Track color */
}

/* Webkit (Chrome, Safari, Edge) */
.custom-scrollbar::-webkit-scrollbar {
  height: 6px; /* Horizontal scrollbar height */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f8fafc; /* Light track background */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #cbd5e1; /* Thumb color */
  border-radius: 10px;
  border: 1px solid #f8fafc; /* Creates a subtle border around the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #94a3b8; /* Darker thumb on hover */
}


@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  60% {
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-3px);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes menuReveal {
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Additional mobile-specific styles */
.mobile-select,
.mobile-input {
  -webkit-appearance: none;
  appearance: none;
  font-size: 16px !important;
  height: 2.75rem;
  line-height: 1.25;
}

/* Ensure proper spacing around icons */
.mobile-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

/* Ensure proper text alignment */
@supports (-webkit-touch-callout: none) {
  .mobile-select,
  .mobile-input {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

/* Mobile date input specific fixes */
.mobile-date-input {
  /* Prevent text overlap */
  text-indent: 0;
  padding-left: 2.75rem !important;
  line-height: 1.25;
  height: 2.75rem;
  font-size: 16px !important; /* Prevent auto-zoom */
}
